import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from 'react-query';
import { APIError } from 'types';

import {
  confirmEmailValidation,
  getLoadLoginRedirectQueryKey,
  loadLoginRedirect,
  requestEmailVerification,
} from './api';
import { ConfirmEmailValidationPayload, LoginRedirectResponse } from './types';

export const useMutationConfirmEmailValidation = (
  options?: UseMutationOptions<null, APIError, ConfirmEmailValidationPayload>,
) => {
  return useMutation(
    (payload: ConfirmEmailValidationPayload) => confirmEmailValidation(payload),
    options,
  );
};

export const useMutationRequestValidateEmail = (
  options?: UseMutationOptions<null, APIError, string>,
) => {
  return useMutation(
    (userEmail: string) => requestEmailVerification(userEmail),
    options,
  );
};

export const useQueryLoadLoginRedirect = (
  phoneNumber?: string,
  redirectUri?: string,
  options: UseQueryOptions<LoginRedirectResponse, APIError> = {},
) =>
  useQuery(
    getLoadLoginRedirectQueryKey(phoneNumber, redirectUri),
    () => loadLoginRedirect(phoneNumber, redirectUri),
    {
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      retry: 0,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
