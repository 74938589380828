import config from 'config';

import ApiClient from 'app-libs/redux/ApiClient';

import { ConfirmEmailValidationPayload, LoginRedirectResponse } from './types';

export const getLoadLoginRedirectQueryKey = (
  phoneNumber?: string,
  redirectUri?: string,
) => ['unm-login-redirect', phoneNumber, redirectUri];

export const confirmEmailValidation = async (
  payload: ConfirmEmailValidationPayload,
) => {
  const { uidb64, token } = payload;
  const res = await new ApiClient().post(
    `${config.API_URL_SURTUR_V2}/verify-email/confirm/${uidb64}/${token}/`,
  );

  if (!res.error) return res;
  throw new Error('Terjadi Kesalahan');
};

export const requestEmailVerification = async (userEmail: string) => {
  const payload = {
    email: userEmail,
  };
  const res = await new ApiClient().post(
    `${config.API_URL_SURTUR_V2}/verify-email/`,
    { data: payload },
  );

  if (res.error === false) return res.body;
  throw new Error('Terjadi Kesalahan');
};

export const loadLoginRedirect = async (
  phoneNumber?: string,
  redirectUri?: string,
): Promise<LoginRedirectResponse> => {
  const url = new URL(`${config.API_URL_SURTUR}/unm-login-redirect/`);

  if (phoneNumber) url.searchParams.append('phone_number', phoneNumber);
  if (redirectUri) url.searchParams.append('redirect_uri', redirectUri);

  const currentUrl = new URL(window.location.href);
  if (currentUrl.searchParams.has('beta')) {
    url.searchParams.append('beta', 'true');
  }

  const res = await new ApiClient().get(url.toString());
  return res;
};
