import React, { useEffect, useMemo } from 'react';
import { FCFetchData } from 'types';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet, View } from '@dkrm/ui-kit-basic';
import { ActivityIndicator } from '@dkrm/ui-kit-v2';

import { useRedirectIsLoggedInEffect } from 'app-libs/hook_modules/redirectLogin';
import { load as loadAuth, verifyOTPToken } from 'app-libs/redux_modules/auth';
import { isAuthLoaded } from 'app-libs/redux_modules/selectors/auth';
import { trackViewedLoginOrRegisterPhoneNumberPage } from 'app-libs/trackers';

import OTPVerification from 'components/OTPVerification';
import {
  K_STATE_PHONE_NUMBER_LOGIN_OTP_VERIFICATION,
  K_STATE_PHONE_NUMBER_REGISTER_OTP_VERIFICATION,
} from 'components/account/components/constants';

import LoginOrRegister from './LoginOrRegister';
import Register from './Register';
import {
  K_STATE_PHONE_NUMBER_LOGIN_OR_REGISTER,
  K_STATE_PHONE_NUMBER_REGISTER,
} from './constants';
import {
  usePhoneNumberLoginOrRegisterHandler,
  useUnmLoginRedirect,
} from './utils';

const K_SUCCESS_VERIFIED_OTP_STATUS = 'ok';

const K_COMPONENT_BY_STATE: Record<string, React.FC<any>> = {
  [K_STATE_PHONE_NUMBER_LOGIN_OR_REGISTER]: LoginOrRegister,
  [K_STATE_PHONE_NUMBER_LOGIN_OTP_VERIFICATION]: OTPVerification,
  [K_STATE_PHONE_NUMBER_REGISTER_OTP_VERIFICATION]: OTPVerification,
  [K_STATE_PHONE_NUMBER_REGISTER]: Register,
};

const LoginOrRegisterPhoneNumberPage: FCFetchData = () => {
  const {
    formInitialValues,
    formState,
    goblinErrorMessage,
    handlePhoneNumberAvailability,
    handlePhoneNumberLogin,
    handlePhoneNumberRegister,
    setFormState,
  } = usePhoneNumberLoginOrRegisterHandler();

  useRedirectIsLoggedInEffect();
  useEffect(() => {
    trackViewedLoginOrRegisterPhoneNumberPage();
  }, []);

  const ActiveComponent = K_COMPONENT_BY_STATE[formState];

  /*
   * @note(Radit): the onSubmit here is actually for formik use.
   * so in the Component it will be pass as `handleSubmit` instead of `onSubmit` props.
   */
  const propsByState: Record<string, object> = useMemo(
    () => ({
      [K_STATE_PHONE_NUMBER_LOGIN_OR_REGISTER]: {
        onSubmit: handlePhoneNumberAvailability,
        initialValues: formInitialValues,
        formErrorMessage: goblinErrorMessage,
        setFormState,
      },
      [K_STATE_PHONE_NUMBER_LOGIN_OTP_VERIFICATION]: {
        phoneNumber: formInitialValues.phoneNumber,
        onCloseForm: () => setFormState(K_STATE_PHONE_NUMBER_LOGIN_OR_REGISTER),
        onVerifyOTP: handlePhoneNumberLogin,
      },
      [K_STATE_PHONE_NUMBER_REGISTER_OTP_VERIFICATION]: {
        phoneNumber: formInitialValues.phoneNumber,
        onCloseForm: () => setFormState(K_STATE_PHONE_NUMBER_LOGIN_OR_REGISTER),
        onVerifyOTP: verifyOTPToken,
        onSuccessVerifyOTP: (result: { status: string }) => {
          const { status } = result;
          if (status === K_SUCCESS_VERIFIED_OTP_STATUS) {
            setFormState(K_STATE_PHONE_NUMBER_REGISTER);
          }
        },
      },
      [K_STATE_PHONE_NUMBER_REGISTER]: {
        onSubmit: handlePhoneNumberRegister,
        initialValues: formInitialValues,
        formErrorMessage: goblinErrorMessage,
        setFormState,
      },
    }),
    [
      goblinErrorMessage,
      handlePhoneNumberAvailability,
      handlePhoneNumberRegister,
      handlePhoneNumberLogin,
      formInitialValues,
      setFormState,
    ],
  );

  const activeProps = useMemo(
    () => propsByState[formState],
    [propsByState, formState],
  );

  const { shouldRender } = useUnmLoginRedirect();

  if (!shouldRender) return <ActivityIndicator style={cStyles.mv32} />;

  return (
    <View style={s.container}>
      <ActiveComponent {...activeProps} />
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.pageMaxWidth,
    ...cStyles.pvn,
  },
});

LoginOrRegisterPhoneNumberPage.fetchData = ({ store }) => {
  if (!isAuthLoaded(store.getState())) {
    return store.dispatch(loadAuth());
  }
  return {};
};

export default LoginOrRegisterPhoneNumberPage;
