import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Voucher } from 'app-libs/components/campaign/types';
import { genDekononymousEmail } from 'app-libs/etc/authHelper';
import { internationalizeIndonesianPhoneNumber } from 'app-libs/etc/phoneNumberHelper';
import { useLocation, useQueries } from 'app-libs/hook_modules/router';
import {
  getPhoneNumberRegisterAvailability,
  loginOTP,
  signupWithoutPassword,
} from 'app-libs/redux_modules/auth';
import { setCustomerVouchers } from 'app-libs/redux_modules/entity_modules/voucher';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import {
  trackClickedActionLogin,
  trackClickedActionSignUp,
  trackSuccessSignUp,
} from 'app-libs/trackers';

import {
  K_STATE_PHONE_NUMBER_LOGIN_OTP_VERIFICATION,
  K_STATE_PHONE_NUMBER_REGISTER_OTP_VERIFICATION,
} from 'components/account/components/constants';

import { useQueryLoadLoginRedirect } from 'entities/auth/utils';
import { useMutationCreateWelcomeVoucher } from 'entities/voucher/utils';

import {
  K_FIELD_PHONE_NUMBER,
  K_STATE_PHONE_NUMBER_LOGIN_OR_REGISTER,
} from './constants';

const K_LOGIN_TYPE_ATTEMPT_LOGIN = 'attemptLogin';
const K_SIGN_UP_TYPE_ATTEMPT_SIGN_UP = 'attemptSignUp';

export const usePhoneNumberLoginOrRegisterHandler = () => {
  const dispatch = useDispatch();
  const queries = useQueries();
  const location: {
    state: { formState: string; phoneNumber: string };
  } = useLocation();

  const initialFormState =
    location?.state?.formState ?? K_STATE_PHONE_NUMBER_LOGIN_OR_REGISTER;
  const initialPhoneNumber = location?.state?.phoneNumber ?? '';
  const { referralCode } = queries;

  const [formState, setFormState] = useState(initialFormState);

  const [formInitialValues, setFormInitialValues] = useState({
    [K_FIELD_PHONE_NUMBER]: initialPhoneNumber,
  });
  const [goblinErrorMessage, setGoblinErrorMessage] = useState<string | null>(
    null,
  );

  const handleCreateWelcomeVoucherSuccess = useCallback(
    (res: Voucher) => {
      dispatch(setCustomerVouchers([res]));
    },
    [dispatch],
  );

  const createWelcomeVoucherMutation = useMutationCreateWelcomeVoucher({
    onSuccess: handleCreateWelcomeVoucherSuccess,
  });

  const handlePhoneNumberAvailability = useCallback(
    async (values, formikActions: TYPEFIXME) => {
      setGoblinErrorMessage(null);
      const { phoneNumber: formPhoneNumber } = values;

      const phoneNumber = internationalizeIndonesianPhoneNumber(
        formPhoneNumber.trim(),
      );

      setFormInitialValues({
        ...values,
        phoneNumber,
      });

      const { result } = await dispatch(
        dontShowPromptOnError(getPhoneNumberRegisterAvailability(phoneNumber)),
      );

      if (result) {
        setFormState(K_STATE_PHONE_NUMBER_REGISTER_OTP_VERIFICATION);
      } else {
        setFormState(K_STATE_PHONE_NUMBER_LOGIN_OTP_VERIFICATION);
      }
      formikActions.setSubmitting(false);
    },
    [dispatch],
  );

  const handlePhoneNumberLogin = useCallback(
    (phoneNumber: string, otpCode: string) => {
      trackClickedActionLogin(phoneNumber, K_LOGIN_TYPE_ATTEMPT_LOGIN);

      return loginOTP(phoneNumber, otpCode);
    },
    [],
  );

  const handlePhoneNumberRegister = useCallback(
    async (values, formikActions: TYPEFIXME) => {
      setGoblinErrorMessage(null);
      const { name: firstName, email, phoneNumber } = values;

      const signUpData: {
        firstName: string;
        email: string;
        phoneNumber: string;
        autoLogin: boolean;
      } = {
        firstName,
        email,
        phoneNumber,
        autoLogin: true,
      };

      if (referralCode) {
        Object.assign(signUpData, { referralCode: referralCode.trim() });
      }
      if (!email) {
        const dekononymousEmail = genDekononymousEmail(phoneNumber, firstName);
        Object.assign(signUpData, {
          email: dekononymousEmail,
        });
      }

      trackClickedActionSignUp(signUpData, K_SIGN_UP_TYPE_ATTEMPT_SIGN_UP);

      const { error } = await dispatch(
        dontShowPromptOnError(signupWithoutPassword(signUpData)),
      );

      if (error) {
        setGoblinErrorMessage(error.response.body.error);
      } else {
        createWelcomeVoucherMutation.mutate();
        trackSuccessSignUp(firstName, email, phoneNumber);
      }

      formikActions.setSubmitting(false);
    },
    [createWelcomeVoucherMutation, dispatch, referralCode],
  );

  return {
    formInitialValues,
    formState,
    goblinErrorMessage,
    handlePhoneNumberAvailability,
    handlePhoneNumberLogin,
    handlePhoneNumberRegister,
    setFormState,
  };
};

export const useUnmLoginRedirect = () => {
  const { phoneNumber, redirect } = useQueries();
  const [shouldRender, setShouldRender] = useState(false);

  const { data, error, isFetched } = useQueryLoadLoginRedirect(
    phoneNumber,
    redirect,
  );

  useEffect(() => {
    if (!isFetched) return;

    const currentUrl = window.location.origin + window.location.pathname;

    // if the returned url is the same as current url, dont replace to avoid refresh
    if (!!data && data.loginUrl && data.loginUrl !== currentUrl) {
      window.location.replace(data.loginUrl);
    } else {
      setShouldRender(true);
    }
  }, [data, error, isFetched]);

  return { shouldRender };
};
